<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { usersSessions } from '@consumer/api'
import { asyncOpenModal } from '@consumer/services/modals'
import { useTemplateRefsList } from '@vueuse/core'
import type GInputField from '@consumer/components/GInputField.vue'
import type { InputValidation } from '@shared/validation'
import { isPresent } from '@shared/object'
import { useSharedData } from '@consumer/composables/sharedData'
import { showNotice } from '@consumer/services/flash'
import { isTest } from '@shared/environment'

withDefaults(defineProps<{
  referer?: string
  authenticationError?: string
  errors?: any
}>(), {  })

const credentials = (defineModel<{
  email?: string
  password?: string
  code?: string
  recaptcha?: string
  hasPassword: boolean
  usePassword: boolean
  checkout?: boolean
}>('credentials', { required: true }))

const props = useSharedData()

const revealPassword = _ref(false)

let wardenError = _ref(__props.authenticationError)
watch(() => __props.authenticationError, (message) => {
  if (message) {
    wardenError.value = message
  }
  // Ensure same message is detected as change and displayed each time.
  (props as any).authenticationError = undefined
})

const passwordIsPresent: InputValidation = {
  isValueValid: isPresent,
  errorMessage: () => 'Please enter your password.',
}

// NOTE: Necessary only because warden sets errors in flash[:alert].
const passwordIsValid: InputValidation = {
  isValueValid: () => !wardenError.value,
  errorMessage: () => wardenError.value!,
  immediate: true,
}

async function onSubmit () {
  try {
    const user = {
      email: credentials.value.email,
      password: credentials.value.password,
      code: credentials.value.code,
      recaptcha: credentials.value.recaptcha,
    }
    if (!credentials.value.usePassword || !credentials.value.hasPassword) {
      user.code = codeCharacters.value.join('')
      delete user.password
    }
    else {
      delete user.code
    }
    return await usersSessions.create({
      data: { user, referer: __props.referer, checkout: credentials.value.checkout },
      visit: true,
    })
  }
  catch (error: any) {
    if (error.message) wardenError.value = error.message
  }
}

function openForgotPassword () {
  return asyncOpenModal(import('@consumer/modals/ForgotPasswordModal.vue'), {
    email: credentials.value.email,
  })
}

const codeCharacters = ref(['', '', '', '', '', ''])
onMounted(() => {
  Array.from(credentials.value.code ?? '').forEach((char, index) => {
    codeCharacters.value[index] = char
  })
})
const inputRefs = useTemplateRefsList<InstanceType<typeof GInputField>>()
const submitButtonRef = ref<HTMLElement>()

function loginWithPassword () {
  credentials.value.usePassword = true
  wardenError.value = undefined
}

function loginWithToken () {
  usersSessions.sendLoginLink({ data: { email: credentials.value.email } }).then(() => {
    credentials.value.usePassword = false
    wardenError.value = undefined
  })
}

let isResendingCode = _ref(false)
function resendCode () {
  isResendingCode.value = true
  usersSessions.sendLoginLink({ data: { email: credentials.value.email } }).then(() => {
    showNotice('Check your email for a verification code.')
    isResendingCode.value = false
  })
}

function onCodePaste (index: number, e: ClipboardEvent) {
  const pasteData = e.clipboardData?.getData('text')

  if (pasteData && pasteData.match(/\d{6}/)) {
    codeCharacters.value = pasteData.trim().split('')
    submitButtonRef.value?.focus()
    submitButtonRef.value?.click()
  }
}

function onCodeInput (index: number, e: InputEvent) {
  const key = e.data
  const nextInputRef = inputRefs.value[index + 1]

  if (key && key.match(/\d/) && nextInputRef && index < codeCharacters.value.length - 1) {
    nextInputRef.focus()
  }
}

function onCodeKeyup (index: number, event: KeyboardEvent) {
  const previusInputRef = inputRefs.value[index - 1]
  const key = event.key

  if (key === 'Delete') {
    codeCharacters.value[index] = ''
  }
  else if (key === 'Backspace' && previusInputRef) {
    event.preventDefault()
    previusInputRef.focus()
    codeCharacters.value[index - 1] = ''
  }
}

const showPasswordInput = _computed(() => credentials.value.usePassword && credentials.value.hasPassword)
</script>

<template>
  <GForm :model="credentials" class="w-full" @submit="onSubmit">

    <div v-show="showPasswordInput">
      <GInput
        v-model="credentials!.password"
        v-autofocus
        placeholder="Enter Your Password"
        :type="revealPassword ? 'text' : 'password'"
        :validate="[passwordIsPresent, passwordIsValid]"
        :error="wardenError"
        name="password"
        autocomplete="current-password"
        :label="false"
        @focus="wardenError = undefined"
      >
        <template #addonRight>
          <GIcon
            v-tooltip="`Click to ${revealPassword ? 'hide' : 'show'} password`"
            :name="revealPassword ? 'eye-off' : 'eye'"
            color="grey-400"
            tabindex="-1"
            class="cursor-pointer"
            @click.prevent="revealPassword = !revealPassword"
          />
        </template>
      </GInput>
      <div class="flex items-baseline justify-start mt-4 text-sm">
        Forgot password?
        &nbsp;
        <button
          class="font-semibold text-v2-active hover:underline"
          type="button"
          @click="openForgotPassword"
        >
          Reset
        </button>
        &nbsp;or&nbsp;
        <button
          class="font-semibold text-v2-active hover:underline"
          type="button"
          @click="loginWithToken"
        >
          send code
        </button>
      </div>
    </div>

    <div v-show="!showPasswordInput">
      <div class="flex gap-3 items-center justify-between user-code-group">
        <GInputField
          v-for="(inputCode, i) in codeCharacters"
          :key="i"
          :ref="inputRefs.set"
          v-model="codeCharacters[i]"
          :name="`code[${i}]`"
          class="w-full text-center user-code"
          :class="{
            '!border-red-base !border-1 !border-solid': wardenError,
          }"
          autocapitalize
          :inputSize="1"
          mask="#"
          pattern="\d*"
          @focus="wardenError = undefined"
          @paste="onCodePaste(i, $event)"
          @input="onCodeInput(i, $event)"
          @keyup="onCodeKeyup(i, $event)"
        />
      </div>
      <GInputError :error="wardenError"/>

      <div class="flex items-baseline justify-start mt-4 text-sm">
        <button
          class="font-semibold text-v2-active hover:underline"
          type="button"
          :disabled="isResendingCode"
          @click="resendCode"
        >
          <template v-if="isResendingCode">
            <LoadingIndicator/>
            Resending code...
          </template>
          <template v-else>
            Resend code
          </template>
        </button>
        <template v-if="credentials.hasPassword">
          &nbsp;or&nbsp;
          <button
            class="font-semibold text-v2-active hover:underline"
            type="button"
            @click="loginWithPassword"
          >
            login with password
          </button>
        </template>
      </div>
    </div>

    <GSaveButton v-if="isTest" class="mt-6 w-full" name="login-submit-password" label="Log In"/>
    <GRecaptcha v-else>
      <GSaveButton ref="submitButtonRef" class="mt-6 w-full" name="login-submit-password" label="Log In"/>
    </GRecaptcha>
  </GForm>
</template>

<style lang="scss">
.user-code-group {
  .g-input-field {
    width: 100%;

    .g-input-group {
      width: 100%;

      .g-input.user-code {
        border-radius: 4px;
        min-height: 56px;
        padding: 0 !important;
        width: 100%;
      }
    }
  }
}
</style>
