// JsFromRoutes CacheKey a9f6e27b461aec55a23c2fdef6eb56a0
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  loginLink: /* #__PURE__ */ definePathHelper('get', '/login-link'),
  new: /* #__PURE__ */ definePathHelper('get', '/login'),
  create: /* #__PURE__ */ definePathHelper('post', '/login'),
  verifyEmail: /* #__PURE__ */ definePathHelper('post', '/consumer/sessions/verify_email'),
  forgotPassword: /* #__PURE__ */ definePathHelper('post', '/consumer/sessions/forgot_password'),
  sendLoginLink: /* #__PURE__ */ definePathHelper('post', '/consumer/sessions/send_login_link'),
}
