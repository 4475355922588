<script setup lang="ts">
import { type InputValidation } from '@shared/validation'
import { usersSessions } from '@consumer/api'
import { isPresent } from '@shared/object'

defineProps<{
  credentials: {
    email?: string
    autoCreateAccount?: boolean
  }
}>()

const emit = defineEmits<{
  success: [{ isCorporate?: boolean }]
  unknown: []
  error: [error: any]
}>()

const emailIsPresent: InputValidation<any> = {
  isValueValid: isPresent,
  errorMessage: () => 'Please enter a valid email address.',
}

async function onSubmit (data: { email: string; autoCreateAccount?: boolean }) {
  try {
    const emailInfo = await usersSessions.verifyEmail({ data })
    emit('success', emailInfo)
  }
  catch (error: any) {
    if (error.response?.status === 404) {
      emit('unknown')
      throw new Error('Unknown email address')
    }
    else {
      emit('error', error)
      throw error
    }
  }
}

defineExpose({ onSubmit })
</script>

<template>
  <GForm :model="credentials" :onSubmit="onSubmit" keepFields>
    <GInput
      v-autofocus
      field="email"
      type="email"
      label="Your Email"
      placeholder="Enter your email"
      class="w-full email-input-field"
      autocapitalize="off"
      autocomplete="email"
      :validate="emailIsPresent"
    >
      <template #hint>
        <slot name="hint"/>
      </template>
    </GInput>
    <GSaveButton primary class="mt-4 w-full" name="login-submit-email" label="Next"/>
  </GForm>
</template>
